import { cx } from "@linaria/core";
import React, { memo } from "react";
import { cssBoxDisabled, cssVariationsSelect, VariationsBoxInner } from "@/components/Products/parts/Variations/StyledVariations";
import { VariationsViewPropsType } from "@/components/Products/parts/Variations/types";
import { getProductPath } from "@/hooks/product/helpers";
import { colors } from "@/styles/utils/vars";
import { EntityImage } from "@/ui/EntityImage/EntityImage";
import { Icon } from "@/ui/Icon";
import { Link } from "@/ui/Link";
import { Select } from "@/ui/Select/Select";
import type { SelectPropsType } from "@/ui/Select/types";
import { Typography } from "@/ui/Typography/Typography";
type PickedSelectType = Pick<SelectPropsType, "withAnimatingLabel" | "closeMode">;
const SelectView = memo(({
  items,
  selected,
  onSelect,
  isFetching,
  inCart,
  withAnimatingLabel = true,
  closeMode = "destroy",
  productName = ""
}: VariationsViewPropsType & PickedSelectType) => {
  return <Select closeMode={closeMode} withAnimatingLabel={withAnimatingLabel} ariaLabel={selected?.type || "Вариация"} initialValue={selected?.uuid} className={cssVariationsSelect} items={items.map(({
    uuid = "",
    name = "",
    total_qty = 0,
    image = undefined,
    alias
  }) => {
    const disabled = !total_qty;
    const isSelected = selected?.uuid === uuid;
    return {
      name: name,
      value: uuid,
      disabled: disabled,
      layout: <VariationsBoxInner className={cx(disabled && cssBoxDisabled)}>
                  <EntityImage imagePath={image} width={40} height={40} priority />
                  <Typography variant={"span"}>{name}</Typography>
                  {inCart(uuid) && <Icon NameIcon={"Check"} fill={colors.green} />}
                  {/* ссылка нужна лишь для seo */}
                  {!isSelected && <Link href={getProductPath(alias)} title={productName} textIsHidden scroll onClick={() => {
          onSelect(uuid);
        }}>
                      {name}
                    </Link>}
                </VariationsBoxInner>
    };
  })} variant={"default"} isVisibleLayout onSelectValue={onSelect} isFetching={isFetching} />;
});
SelectView.displayName = "SelectView";
export { SelectView };